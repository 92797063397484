import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Node-RED Installation with Docker",
  "path": "/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_Docker_Installation/",
  "dateChanged": "2022-03-29",
  "author": "Mike Polinowski",
  "excerpt": "Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.",
  "image": "./AU_SearchThumb_Node-RED.png",
  "social": "/images/Search/AU_SearchThumb_Node-RED.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_NodeRED_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Node-RED Installation with Docker' dateChanged='2022-03-29' author='Mike Polinowski' tag='INSTAR IP Camera' description='Smarthomes and automation system often provide HTTP Webhooks to interconnect with third-party systems. Configure your INSTAR Cloud to contact such a webhook when an alarm is being recorded.' image='/images/Search/AU_SearchThumb_Node-RED.png' twitter='/images/Search/AU_SearchThumb_Node-RED.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Node-RED_mit_INSTAR_Cloud_Webhook/Node-RED_Docker_Installation/' locationFR='/fr/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_Docker_Installation/' crumbLabel="Installation" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "node-red-with-the-instar-cloud-webhook",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#node-red-with-the-instar-cloud-webhook",
        "aria-label": "node red with the instar cloud webhook permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED with the INSTAR Cloud Webhook`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-installation-with-docker"
        }}>{`Node-RED Installation with Docker`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#securing-node-red"
        }}>{`Securing Node-RED`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#generating-logins"
            }}>{`Generating Logins`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#adding-admin-and-webhook-security"
            }}>{`Adding Admin and Webhook Security`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#next-steps"
        }}>{`Next Steps`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "node-red-installation-with-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-installation-with-docker",
        "aria-label": "node red installation with docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Installation with Docker`}</h2>
    <p>{`Let's start by installing Node-RED using Docker. This way - `}<a parentName="p" {...{
        "href": "https://docs.docker.com/get-docker/"
      }}>{`after you installed Docker`}</a>{` - the following guide will work no matter if you setting up Node-RED on an online Webserver, a Raspberry Pi, your NAS or your local Windows Workstation.`}</p>
    <p>{`After you set up Docker run the two following commands to pull the latest Node-RED Docker image (or pick a specific version that you need from `}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/nodered/node-red"
      }}>{`Docker Hub`}</a>{`) and prepare the virtual network we want to confine our containers in (I will call it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`smart`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker pull nodered/node-red:latest
docker network create smart`}</code></pre></div>
    <p>{`Whenever the Node-RED Container is "destroyed" you will loose everything that you configured. To get around this I will mount a folder from my host system into the container that will hold on to all the data that is generated by Node-RED, e.g. the configuration files. On a LINUX System you have to be careful about the permissions when creating these folders:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/nodered/data
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` -R `}<span parentName="code" {...{
            "className": "token number"
          }}>{`775`}</span>{` /opt/nodered
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chown`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1000`}</span>{`:1000 -R /opt/nodered`}</code></pre></div>
    <p>{`You do not have to create this folder in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/opt`}</code>{` just pick one where the Docker daemon has the necessary rights to read and write data. If you change the directory make sure to also change the volume mount in the following `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker run`}</code>{` command. `}</p>
    <p>{`Now I am able to run the Node-RED container with:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d --rm --privileged --network`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`smart -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1880`}</span>{`:1880 -v /opt/nodered/data:/data --name nodered nodered/node-red:latest`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: Here I am opening the port `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`1880`}</code>{` to be able to access the Node-RED UI directly. When you plan on using a web proxy like `}<a parentName="p" {...{
          "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_NGINX_Proxy/"
        }}>{`NGINX to add HTTP/2 Support`}</a>{` you can remove the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`-p 1880:1880`}</code>{`. In this case all traffic will flow through your web proxy and you don't need to expose any ports on the Node-RED container.`}</p>
    </blockquote>
    <p>{`If you opened the port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1880`}</code>{` you are now able to access the Node-RED user interface on servers IP address or assigned domain name:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`http://my.server.address:1880/`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a3dcd7df691d0c39e48b538e7f69908/1dbe8/Node-RED_INSTAR_Cloud_Webhook_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAkklEQVQI133BPQrCMBgA0NxUssQ2YNUK4j28goOj4O7m0LGbS0FtKVoHqZikzfcTwb19TyQ6Xi7mURxJKdXfdIBSaiLlNl3v080q0nqWiN5j16EHYOYwChGdM8QADBwghCCyS747HYv7DWEMIVrXNY/SvisyT7YNuZe4Fnl2PtR1ScQ0jJnB+7b9fI01xvUeAfkH6OHGRlve3PoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a3dcd7df691d0c39e48b538e7f69908/e4706/Node-RED_INSTAR_Cloud_Webhook_01.avif 230w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/d1af7/Node-RED_INSTAR_Cloud_Webhook_01.avif 460w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/7f308/Node-RED_INSTAR_Cloud_Webhook_01.avif 920w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/8922e/Node-RED_INSTAR_Cloud_Webhook_01.avif 1374w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a3dcd7df691d0c39e48b538e7f69908/a0b58/Node-RED_INSTAR_Cloud_Webhook_01.webp 230w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/bc10c/Node-RED_INSTAR_Cloud_Webhook_01.webp 460w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/966d8/Node-RED_INSTAR_Cloud_Webhook_01.webp 920w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/04d72/Node-RED_INSTAR_Cloud_Webhook_01.webp 1374w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a3dcd7df691d0c39e48b538e7f69908/81c8e/Node-RED_INSTAR_Cloud_Webhook_01.png 230w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/08a84/Node-RED_INSTAR_Cloud_Webhook_01.png 460w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/c0255/Node-RED_INSTAR_Cloud_Webhook_01.png 920w", "/en/static/5a3dcd7df691d0c39e48b538e7f69908/1dbe8/Node-RED_INSTAR_Cloud_Webhook_01.png 1374w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a3dcd7df691d0c39e48b538e7f69908/c0255/Node-RED_INSTAR_Cloud_Webhook_01.png",
              "alt": "Node-RED Installation with Docker",
              "title": "Node-RED Installation with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "securing-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#securing-node-red",
        "aria-label": "securing node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Securing Node-RED`}</h2>
    <p>{`We now have set up an instance of Node-RED that is fully exposed to the vastness of the internet. It is time to take care of security. When you take a look into the directory we mounted into the Node-RED container you will see that it now contains the main Node-RED configuration file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`settings.js`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{` /opt/nodered/data

lib  node_modules  package.json  package-lock.json  settings.js`}</code></pre></div>
    <p>{`Here we have to set up two user - the `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/user-guide/runtime/securing-node-red"
      }}>{`Admin API Login`}</a>{` and the `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/user-guide/runtime/securing-node-red"
      }}>{`HTTP Node Login`}</a>{`. The latter is going to be used to protect our webhooks.`}</p>
    <h3 {...{
      "id": "generating-logins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#generating-logins",
        "aria-label": "generating logins permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Generating Logins`}</h3>
    <p>{`Node-RED offers a password tool that we can use to generate the logins we need. Since we are running Node-RED in Docker we will have to execute these commands throught the Docker CLI. E.g. to generate the login "username=admin" and "password=instar" run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`exec`}</span>{` -ti nodered node-red admin hash-pw

Password: instar
`}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$2b`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$08`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$n6OUy4T5V5sF`}</span>{`/dhpqvNxm.VS8Psht8E8Z3aDRS5mIECcGihPRvQhy`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` this command executes inside our Node-RED container because we assigned the name `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`nodered`}</code>{` to it when executing the RUN command with the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`--name nodered`}</code>{` flag.`}</p>
    </blockquote>
    <h3 {...{
      "id": "adding-admin-and-webhook-security",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-admin-and-webhook-security",
        "aria-label": "adding admin and webhook security permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding Admin and Webhook Security`}</h3>
    <p>{`We can now edit the configuration file and add our generated logins. In the following example I am going to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin:instar`}</code>{` login for both users. `}<strong parentName="p">{`You should create your own logins here`}</strong>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` /opt/nodered/data/settings.js`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`module`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`exports `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    flowFile`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'flows.json'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    flowFilePretty`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    adminAuth`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        type`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"credentials"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        users`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            username`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            password`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"$2b$08$n6OUy4T5V5sF/dhpqvNxm.VS8Psht8E8Z3aDRS5mIECcGihPRvQhy"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            permissions`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"*"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    httpNodeAuth`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`user`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"admin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`pass`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"$2b$08$n6OUy4T5V5sF/dhpqvNxm.VS8Psht8E8Z3aDRS5mIECcGihPRvQhy"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`...`}</span>{`

`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now restart the container and you should now be greeted by a user login. Try to login with your personal login:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker stop nodered `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` docker start nodered`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0ed10ba3cdf719964f97a67a47eb3243/8dd93/Node-RED_INSTAR_Cloud_Webhook_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA3UlEQVQY04WQQWrCQBiF5xCKew/lyt7BRc4g9AAWF6Hg0o2b4l6k6UwCDjgliXam808weoAuKphkXrEgZFHst3vwf7zHz+IWQoiNlKYszfFoTqcP59IbWZZJKYUQ7XvWDm+c7/N8P5+/z2bbMCxWKw/U1RUA1tooipIk+VvmcZwr9dTpPDI2ZuxlMGgA3zTeewCO6H950u1Oe73nfn85fPj6Pl+q+lLVAOiOnPzO1rtdFATr0eg1CD4XCwDwV+rGE7m7zZxvlSJjrNYmTUnrQ1kWhXOuILJKKc55+2E/7a4a/TZjeoIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ed10ba3cdf719964f97a67a47eb3243/e4706/Node-RED_INSTAR_Cloud_Webhook_02.avif 230w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/d1af7/Node-RED_INSTAR_Cloud_Webhook_02.avif 460w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/7f308/Node-RED_INSTAR_Cloud_Webhook_02.avif 920w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/19805/Node-RED_INSTAR_Cloud_Webhook_02.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0ed10ba3cdf719964f97a67a47eb3243/a0b58/Node-RED_INSTAR_Cloud_Webhook_02.webp 230w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/bc10c/Node-RED_INSTAR_Cloud_Webhook_02.webp 460w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/966d8/Node-RED_INSTAR_Cloud_Webhook_02.webp 920w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/61eb3/Node-RED_INSTAR_Cloud_Webhook_02.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0ed10ba3cdf719964f97a67a47eb3243/81c8e/Node-RED_INSTAR_Cloud_Webhook_02.png 230w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/08a84/Node-RED_INSTAR_Cloud_Webhook_02.png 460w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/c0255/Node-RED_INSTAR_Cloud_Webhook_02.png 920w", "/en/static/0ed10ba3cdf719964f97a67a47eb3243/8dd93/Node-RED_INSTAR_Cloud_Webhook_02.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0ed10ba3cdf719964f97a67a47eb3243/c0255/Node-RED_INSTAR_Cloud_Webhook_02.png",
              "alt": "Node-RED Installation with Docker",
              "title": "Node-RED Installation with Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/INSTAR_Cloud/Webhook/"
        }}>{`The INSTAR Cloud Webhook`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_GET_Webhook"
            }}>{`Node-RED GET Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_POST_Webhook"
            }}>{`Node-RED POST Webhook`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Advanced_User/Node-RED_with_INSTAR_Cloud_Webhook/Node-RED_NGINX_Proxy"
            }}>{`Node-RED adding a HTTP/2 Webproxy`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      